import { Module } from './types';
export const getModule = (item?: Module) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    description: item?.description ?? '',
    duration: item?.duration ?? 0,
    order: item?.order ?? 0,
  };
};

export const getModulesList = (data?: Module[]) => {
  return data?.length
    ? data.map(item => {
        return getModule(item);
      })
    : [];
};
