import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { GetTournamentParticipantsExcel } from '../api';
import downloadFile from 'utils/downloadFile';

export const useTournamentParticipantsExcel = (tournamentId: string) => {
  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: () => GetTournamentParticipantsExcel(tournamentId),
    onSuccess: ({ data }) =>
      downloadFile({ data, fileName: 'musobaqa-ishtirokchilari' }),
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerParicipantsExcel: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
