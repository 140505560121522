import { ColumnDef } from '@tanstack/react-table';
import { Course } from 'modules/courses/types';
import { Play } from 'lucide-react';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { timeConverter } from 'utils/timeConverter';

interface IProps {
  getRowData: (course: Course) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createCourseColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Course>[] => [
  {
    accessorKey: 'name',
    header: 'Kurs nomi',
  },
  {
    accessorKey: 'short_description',
    header: 'Tavsifi',
  },
  {
    accessorKey: 'lessons_count',
    header: 'Dars soni',
    cell: ({ row }) => (
      <div className="flex items-center gap-1">
        <Play className="w-4 h-4 stroke-1" />
        {row.getValue('lessons_count')}
      </div>
    ),
  },
  {
    accessorKey: 'duration',
    header: 'Davomiyligi',
    cell: ({ row }) => {
      const duration = row.getValue('duration') as number;
      return <span>{timeConverter(duration)}</span>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
