import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  GrandmasterBot,
  GrandmasterBotInput,
} from 'modules/grandmasters-bot/types';

import { Form } from 'components/ui/form';
import { FileField, TextAreaField, TextField } from 'components/fields';
import { useCreateGrandmasterBot } from 'modules/grandmasters-bot/hooks/useCreateGrandmasterBot';
import { useEditGrandmasterBot } from 'modules/grandmasters-bot/hooks/useEditGrandmasterBot';
import LoadingButton from 'components/LoadingButton';
import useFileUploader from 'hooks/useFileUploader';

const GrandmasterBotSchema = z.object({
  title: z.string().min(3, { message: 'Bot nomi talab qilinadi' }),
  description: z.string().min(3, { message: 'Tavsif talab qilinadi' }),
  link: z.string().url({ message: "Noto'g'ri link kiritilgan" }),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type GrandmasterBotFormSchema = z.infer<typeof GrandmasterBotSchema>;

interface IProps {
  grandmasterBot?: GrandmasterBot;
  setSheetOpen: (state: boolean) => void;
}

export default function GrandmasterBotForm({
  grandmasterBot,
  setSheetOpen,
}: IProps) {
  const { uploadFile } = useFileUploader();
  const {
    triggerGrandmasterBotCreate,
    isPending: isGrandmasterBotCreatePending,
  } = useCreateGrandmasterBot({ setSheetOpen });
  const { triggerGrandmasterBotEdit, isPending: isGrandmasterBotEditPending } =
    useEditGrandmasterBot({
      id: grandmasterBot?._id,
      setSheetOpen,
    });

  const form = useForm<GrandmasterBotFormSchema>({
    resolver: zodResolver(GrandmasterBotSchema),
    defaultValues: grandmasterBot
      ? {
          title: grandmasterBot.title,
          description: grandmasterBot.description,
          link: grandmasterBot.link,
          image: grandmasterBot.image,
        }
      : {
          title: '',
          description: '',
          link: '',
          image: undefined,
        },
  });

  async function onSubmit(formValues: GrandmasterBotFormSchema) {
    const values = await uploadFile<GrandmasterBotInput>(formValues, 'image');

    if (grandmasterBot) {
      triggerGrandmasterBotEdit(values);
    } else {
      triggerGrandmasterBotCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Bot nomi" required />
          <TextAreaField name="description" label="Tavsifi" required />
          <TextField name="link" label="Havolasi" required />
          <FileField name="image" label="Rasmi" required />
        </div>
        {grandmasterBot ? (
          <LoadingButton isLoading={isGrandmasterBotEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isGrandmasterBotCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
