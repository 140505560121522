import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';

import { GrandmasterBotInput } from '../types';
import { EditGrandmasterBot } from '../api';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHook {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditGrandmasterBot = ({ id = '', setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: GrandmasterBotInput) =>
      EditGrandmasterBot({ values, id }),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Grandmaster boti muvaffaqiyatli tahrirlandi.',
      });
      queryClient.invalidateQueries({ queryKey: ['grandmaster_bots_list'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerGrandmasterBotEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
