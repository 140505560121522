export interface TournamentParticipant {
  _id: string;
  user_status: PariticipantStatus;
  dob: string;
  level: ParticipantLevel;
  user: User;
  profile: Profile;
}

export enum ParticipantLevel {
  AMATEUR = 'amateur',
  PROFESSIONAL = 'professional',
}

export enum PariticipantStatus {
  CONTACTED = 'contacted',
  NOT_CONTACTED = 'not_contacted',
}

export interface User {
  _id: string;
  phone_number: string;
}

export interface Profile {
  _id: string;
  first_name: string;
  last_name: string;
  address: Address;
}

export interface Address {
  country: string;
  region: string;
  district: string;
  neighborhood: string;
}

export interface ParticipantStatusInput {
  user: string;
  user_status: string;
}
