import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from 'components/DataTableColumnHeader';
import { UsersCount } from 'modules/statistics/types';
import formatNumber from 'utils/formatNumber';

export const createCountryColumns: ColumnDef<UsersCount>[] = [
  {
    accessorKey: '_id',
    header: 'Davlat',
  },
  {
    accessorKey: 'profiles',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Foydalanuvchilar soni" />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('profiles'))}</p>,
  },
  {
    accessorKey: 'submissions',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Bajarilgan boshqotirmalar soni"
      />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('submissions'))}</p>,
  },
];

export const createRegionColumns: ColumnDef<UsersCount>[] = [
  {
    accessorKey: '_id',
    header: 'Viloyat',
  },
  {
    accessorKey: 'profiles',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Foydalanuvchilar soni" />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('profiles'))}</p>,
  },
  {
    accessorKey: 'submissions',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Bajarilgan boshqotirmalar soni"
      />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('submissions'))}</p>,
  },
];

export const createDistrictColumns: ColumnDef<UsersCount>[] = [
  {
    accessorKey: '_id',
    header: 'Tuman/Shahar',
  },
  {
    accessorKey: 'profiles',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Foydalanuvchilar soni" />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('profiles'))}</p>,
  },
  {
    accessorKey: 'submissions',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Bajarilgan boshqotirmalar soni"
      />
    ),
    cell: ({ row }) => <p>{formatNumber(row.getValue('submissions'))}</p>,
  },
];

export const createNeighborhoodColumns: ColumnDef<UsersCount>[] = [
  {
    accessorKey: '_id',
    header: 'Mahalla',
  },
  {
    accessorKey: 'profiles',
    header: 'Foydalanuvchilar soni',
    cell: ({ row }) => <p>{formatNumber(row.getValue('profiles'))}</p>,
  },
  {
    accessorKey: 'submissions',
    header: 'Bajarilgan boshqotirmalar soni',
    cell: ({ row }) => <p>{formatNumber(row.getValue('submissions'))}</p>,
  },
];
