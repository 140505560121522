import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getUzchessBotsList } from '../adapters';
import { GetUzchessBotsList } from '../api';

export const useUzchessBotsList = (id: string) => {
    const initialData = {
        data: getUzchessBotsList(),
        paginationInfo: null,
    };
    const { data = initialData, ...args } = useQuery({
        queryKey: ['uzchess_bots_list'],
        queryFn: () => GetUzchessBotsList(id),
        select: data => ({
            data: getUzchessBotsList(get(data, 'data.data')),
            paginationInfo: get(data, 'data.pagination'),
        }),
    });

    return {
        ...data,
        ...args,
    };
};
