import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Tournament,
  TournamentFormat,
  TournamentInput,
} from 'modules/tournaments/types';

import useFileUploader from 'hooks/useFileUploader';
import { useCreateTournament } from 'modules/tournaments/hooks/useCreateTournament';
import { useEditTournament } from 'modules/tournaments/hooks/useEditTournament';
import { Form } from 'components/ui/form';
import {
  TextField,
  DatePickerField,
  FileField,
  MultiTextField,
  SelectField,
  CheckboxField,
} from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { tournamentFormats } from 'constants/index';
import { useToast } from 'components/ui/use-toast';

const tournamentSchema = z
  .object({
    name: z.string().min(1, { message: 'Musobaqa nomini kiriting' }),
    tournament_type: z.array(z.string()),
    tournament_date: z.date({
      message: 'Musobaqa boshlanish vaqtini kiriting',
    }),
    registration_date: z.date({
      message: "Musobaqaga ro'yxatdan o'tish vaqtini tugashini kiriting",
    }),
    format: z.nativeEnum(TournamentFormat),
    location: z.object({
      name: z.string().min(1, { message: 'Lokatsiya nomi' }),
      address: z
        .string()
        .url({ message: "Lokasiya uchun to'g'ri havola kiriting" }),
    }),
    image: z.union([
      z.custom<File>(file => file instanceof File, {
        message: 'Rasm talab qilinadi',
      }),
      z.string().min(1, { message: 'Rasm talab qilinadi' }),
    ]),
    isPaid: z.boolean().optional(),
    price: z.union([z.string(), z.number()]),
  })
  .refine(data => !data.isPaid || (data.isPaid && data.price !== undefined), {
    message: 'Narxni kiriting',
    path: ['price'],
  });

type tournamentFormSchema = z.infer<typeof tournamentSchema>;

interface IProps {
  tournament?: Tournament;
  setSheetOpen: (state: boolean) => void;
}

export default function TournamentForm({ tournament, setSheetOpen }: IProps) {
  const { toast } = useToast();
  const { uploadFile } = useFileUploader();
  const { triggerTournamentCreate, isPending: isTournamentCreatePending } =
    useCreateTournament({ setSheetOpen });
  const { triggerTournamentEdit, isPending: isTournamentEditPending } =
    useEditTournament({
      id: tournament?._id,
      setSheetOpen,
    });

  const form = useForm<tournamentFormSchema>({
    resolver: zodResolver(tournamentSchema),
    defaultValues: tournament
      ? {
          name: tournament.name,
          tournament_type: tournament.tournament_type,
          tournament_date: new Date(tournament.tournament_date),
          registration_date: new Date(tournament.registration_date),
          format: tournament.format,
          location: tournament.location,
          image: tournament.image,
          price: tournament.price,
        }
      : {
          name: '',
          tournament_type: [],
          tournament_date: undefined,
          registration_date: undefined,
          format: TournamentFormat.OFFLINE,
          location: { name: '', address: '' },
          isPaid: false,
          price: 0,
          image: undefined,
        },
  });

  async function onSubmit(formValues: tournamentFormSchema) {
    delete formValues.isPaid;

    const priceStr =
      typeof formValues.price === 'string'
        ? formValues.price.replace(/\s+/g, '')
        : formValues.price;

    const price = typeof priceStr === 'string' ? Number(priceStr) : priceStr;

    if (isNaN(price)) {
      toast({ title: "Narxni to'g'ri formatda kiriting! (harflarsiz)" });
      return;
    }

    const values = await uploadFile<TournamentInput>(
      { ...formValues, price },
      'image'
    );

    if (tournament) {
      triggerTournamentEdit(values);
    } else {
      triggerTournamentCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Musobaqa nomi" required />
          <TextField
            name="location.name"
            label="Musobaqa o'tkaziladigan joy nomi"
            required
          />
          <TextField
            name="location.address"
            label="Musobaqa o'tkaziladigan joy manzili (havolasi)"
            required
          />
          <SelectField
            name="format"
            data={tournamentFormats}
            label="Musobaqa formati"
            required
          />
          <DatePickerField
            name="tournament_date"
            label="Musobaqa vaqti"
            required
          />
          <DatePickerField
            name="registration_date"
            label="Ro'yxatdan o'tish tugash vaqti"
            required
          />
          <CheckboxField name="isPaid" label="Musobaqa pullikmi?" required />
          {form.getValues('isPaid') && (
            <TextField name="price" label="Musobaqa narxi" required />
          )}
          <MultiTextField
            name="tournament_type"
            label="Musobaqa turi"
            addBtnTitle="Musobaqa turini qo'shish"
            required
          />
          <FileField name="image" label="Musobaqa rasmi" required />
        </div>
        {tournament ? (
          <LoadingButton isLoading={isTournamentEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isTournamentCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
