import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  PariticipantStatus,
  ParticipantLevel,
  TournamentParticipant,
} from 'modules/tournament-participants/types';
import { useChangeParticipantStatus } from 'modules/tournament-participants/hooks/useChangeStatus';
import { Form } from 'components/ui/form';
import { Label } from 'components/ui/label';
import LoadingButton from 'components/LoadingButton';
import calculateAge from 'utils/calculateAge';
import { RadioGroupField } from 'components/fields';
import { participantStatus } from 'constants/index';

const participantSchema = z.object({
  user_status: z.nativeEnum(PariticipantStatus),
});

type participantFormShcema = z.infer<typeof participantSchema>;

interface IProps {
  participant: TournamentParticipant;
  setSheetOpen: (state: boolean) => void;
}

export default function ParticipantForm({ participant, setSheetOpen }: IProps) {
  const { tournamentId } = useParams();
  const {
    profile: {
      first_name,
      last_name,
      address: { region, district, neighborhood },
    },
    user: { _id, phone_number },
    dob,
    level,
    user_status,
  } = participant;
  const participantInfo = [
    { title: 'Viloyat:', label: region },
    { title: 'Tuman:', label: district },
    { title: 'Mahalla:', label: neighborhood },
    { title: 'Yoshi:', label: calculateAge(new Date(dob)) },
    { title: 'Telefon raqami:', label: phone_number },
    {
      title: 'Darajasi:',
      label: level === ParticipantLevel.AMATEUR ? 'Havaskor' : 'Professional',
    },
  ];

  const form = useForm<participantFormShcema>({
    resolver: zodResolver(participantSchema),
    defaultValues: participant
      ? {
          user_status,
        }
      : {
          user_status: undefined,
        },
  });

  const { triggerStatusChange, isPending } = useChangeParticipantStatus({
    id: tournamentId!,
    setSheetOpen,
  });

  const onSubmit = async (formValues: participantFormShcema) => {
    triggerStatusChange({ user: _id, ...formValues });
  };

  return (
    <>
      <div className="p-4 bg-white shadow-md rounded-lg">
        <Label className="text-lg font-semibold">{`${last_name} ${first_name}`}</Label>
        {participantInfo.map(item => (
          <div className="flex items-end space-x-4" key={item.title}>
            <Label className="text-base font-semibold">{item.title}</Label>
            <Label className="text-sm text-gray-500">{item.label}</Label>
          </div>
        ))}
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-5 mt-6"
        >
          <RadioGroupField
            name="user_status"
            label="Ishtirokchining holati"
            data={participantStatus}
            required
          />
          <LoadingButton isLoading={isPending}>Saqlash</LoadingButton>
        </form>
      </Form>
    </>
  );
}
