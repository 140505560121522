import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { TextAreaField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { ModuleBot } from 'modules/module-bot/types';
import { useCreateModuleBot } from 'modules/module-bot/hooks/useCreateModuleBot';
import { useEditModuleBot } from 'modules/module-bot/hooks/useEditModuleBot';

const ModuleBotSchema = z.object({
  name: z.string().min(3, { message: 'Bot nomi talab qilinadi' }),
  description: z.string().min(3, { message: 'Tavsif talab qilinadi' }),
});

type ModuleBotFormSchema = z.infer<typeof ModuleBotSchema>;

interface IProps {
  moduleBot?: ModuleBot;
  setSheetOpen: (state: boolean) => void;
}

export default function ModuleBotForm({ moduleBot, setSheetOpen }: IProps) {
  const { triggerModuleBotCreate, isPending: isModuleBotCreatePending } =
    useCreateModuleBot({ setSheetOpen });
  const { triggerModuleBotEdit, isPending: isModuleBotEditPending } =
    useEditModuleBot({
      id: moduleBot?._id,
      setSheetOpen,
    });

  const form = useForm<ModuleBotFormSchema>({
    resolver: zodResolver(ModuleBotSchema),
    defaultValues: moduleBot
      ? {
          name: moduleBot?.name,
          description: moduleBot.description,
        }
      : {
          name: '',
          description: '',
        },
  });

  async function onSubmit(formValues: ModuleBotFormSchema) {
    if (moduleBot) {
      triggerModuleBotEdit(formValues);
    } else {
      triggerModuleBotCreate(formValues);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Bot nomi" required />
          <TextAreaField name="description" label="Tavsifi" required />
        </div>
        {moduleBot ? (
          <LoadingButton isLoading={isModuleBotEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isModuleBotCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
