import { Puzzle, PuzzleDifficulty, PuzzleReport } from './types';
export const getPuzzle = (item?: Puzzle) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    board_state: item?.board_state ?? '',
    correct_moves: item?.correct_moves?.length ? item.correct_moves : [],
    puzzle_type: item?.puzzle_type ?? PuzzleDifficulty.EASY,
    index: item?.index ?? 0,
    report_count: item?.report_count ?? 0,
  };
};

export const getPuzzlesList = (data?: Puzzle[]) => {
  return data?.length
    ? data.map(item => {
        return getPuzzle(item);
      })
    : [];
};

export const getPuzzleReport = (item?: PuzzleReport) => {
  return {
    _id: item?._id ?? '',
    body: item?.body ?? '',
    is_wrong_answer: item?.is_wrong_answer ?? false,
    is_technical_error: item?.is_technical_error ?? false,
  };
};

export const getPuzzleReports = (data?: PuzzleReport[]) => {
  return data?.length
    ? data.map(item => {
        return getPuzzleReport(item);
      })
    : [];
};
