export enum PuzzleDifficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export interface Puzzle {
  _id: string;
  title: string;
  board_state: string;
  correct_moves: string[];
  puzzle_type: PuzzleDifficulty;
  index: number;
  report_count: number;
}

export interface PuzzleInput {
  title?: string;
  board_state: string;
  correct_moves: string[];
  puzzle_type: PuzzleDifficulty;
}

export interface PuzzleEditBody {
  id: string;
  values: PuzzleInput;
}

export interface PuzzleReport {
  _id: string;
  body: string;
  is_wrong_answer: boolean;
  is_technical_error: boolean;
}
