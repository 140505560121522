import http from 'services/api';
import {
  GrandmasterBot,
  GrandmasterBotEditBody,
  GrandmasterBotInput,
} from './types';

export const GetGrandmasterBotsList = async () => {
  return await http.get(`/grandmaster-bot/`);
};

export const CreateGrandmasterBot = async (values: GrandmasterBotInput) => {
  return await http.post<{ data: GrandmasterBot }>(`/grandmaster-bot/`, values);
};

export const EditGrandmasterBot = async ({
  values,
  id,
}: GrandmasterBotEditBody) => {
  return await http.put(`/grandmaster-bot/${id}`, values);
};

export const DeleteGrandmasterBot = async (id: string) => {
  return await http.delete(`/grandmaster-bot/${id}`);
};
