import { useState } from 'react';
import { Sheet } from 'components/Sheet';
import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import { AlertDialog } from 'components/AlertDialog';
import { createUzchessBotColumns } from './Columns';
import Loader from 'components/Loader';
import { UzchessBot } from 'modules/uzchess-bot/types';
import { useDeleteUzchessBot } from 'modules/uzchess-bot/hooks/useDeleteUzchessBot';
import { useUzchessBotsList } from 'modules/uzchess-bot/hooks/useUzchessBotList';
import UzchessBotForm from './UzchessBotForm';
import { useParams } from 'react-router-dom';

const UzchessBots = () => {
    const { id } = useParams()

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isSheetOpen, setSheetOpen] = useState(false);
    const [uzchessBots, setUzchessBots] = useState<UzchessBot>();

    const {
        data: uzchessBotsList,
        isLoading,
    } = useUzchessBotsList(id!);
    const { triggerUzchessBotDelete } = useDeleteUzchessBot(uzchessBots?._id!);

    const getRowData = (uzchessBots: UzchessBot) => {
        setUzchessBots(uzchessBots);
    };

    const columns = createUzchessBotColumns({
        getRowData,
        setDialogOpen,
        setSheetOpen,
    });

    return (
        <div>
            <TableActions
                sheetTriggerTitle="UzchessBot qo'shish"
                sheetTitle="Yangi uzchessBot qo'shish."
                TableForm={UzchessBotForm}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <DataTable columns={columns} data={uzchessBotsList} />
                </>
            )}

            <Sheet
                sheetTitle="Uzchess botni tahrirlash"
                isOpen={isSheetOpen}
                setSheetOpen={setSheetOpen}
            >
                <UzchessBotForm
                    uzchessBot={uzchessBots}
                    setSheetOpen={setSheetOpen}
                />
            </Sheet>
            <AlertDialog
                alertTitle="Ishonchingiz komilmi?"
                alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
                alertCancel="Bekor qilish"
                alertActionTitle="Davom etish"
                alertActionFunction={triggerUzchessBotDelete}
                isOpen={isDialogOpen}
                setIsOpen={setDialogOpen}
            />
        </div>
    );
};

export default UzchessBots;
