import http from 'services/api';
import { UzchessBot, UzchessBotEditBody, UzchessBotInput } from './types';

export const GetUzchessBotsList = async (id: string) => {
    return await http.get(`uzchess-bot/${id}/bots/`);
};

export const CreateUzchessBot = async (values: UzchessBotInput) => {
    return await http.post<{ data: UzchessBot }>(`/uzchess-bot/`, values);
};

export const EditUzchessBot = async ({ values, id }: UzchessBotEditBody) => {
    return await http.put(`/uzchess-bot/${id}`, values);
};

export const DeleteUzchessBot = async (id: string) => {
    return await http.delete(`/uzchess-bot/${id}`);
};