import { Location, Tournament, TournamentFormat } from './types';

export const getTournament = (item?: Tournament) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    image: item?.image ?? '',
    price: item?.price ?? 0,
    location: getLocation(item?.location),
    tournament_type: item?.tournament_type ?? [],
    tournament_date: item?.tournament_date ?? '',
    registration_date: item?.registration_date ?? '',
    format: item?.format ?? TournamentFormat.OFFLINE,
  };
};

export const getTournamentsList = (data?: Tournament[]) => {
  return data?.length
    ? data.map(item => {
        return getTournament(item);
      })
    : [];
};

function getLocation(item?: Location) {
  return {
    name: item?.name ?? '',
    address: item?.address ?? '',
  };
}
