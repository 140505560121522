import { useFileUpload } from 'modules/file-upload/hooks/useFileUpload';

export default function useFileUploader() {
  const { triggerFileUpload, isPending } = useFileUpload();

  async function uploadFile<TData>(
    values: Record<string, any>,
    key: keyof TData & string
  ): Promise<TData> {
    const formData = new FormData();
    formData.append('file', values[key]);

    if (values[key] instanceof File) {
      const { data } = await triggerFileUpload(formData);
      return { ...values, [key]: data.data.url } as TData;
    }

    return values as TData;
  }

  return { uploadFile, isPending };
}
