import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import {
  Profile,
  TournamentParticipant,
  User,
} from 'modules/tournament-participants/types';
import { participantStatus } from 'constants/index';
import calculateAge from 'utils/calculateAge';

interface IProps {
  getRowData: (tournament: TournamentParticipant) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createParticipantColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<TournamentParticipant>[] => [
  {
    accessorKey: 'profile',
    header: 'Ismi',
    cell: ({ row }) => {
      const { first_name, last_name }: Profile = row.getValue('profile');
      return <span>{`${last_name} ${first_name}`}</span>;
    },
  },
  {
    accessorKey: 'dob',
    header: 'Yoshi',
    cell: ({ row }) => (
      <span>{calculateAge(new Date(row.getValue('dob')))}</span>
    ),
  },
  {
    accessorKey: 'user',
    header: 'Raqami',
    cell: ({ row }) => {
      const { phone_number }: User = row.getValue('user');
      return <span>{phone_number}</span>;
    },
  },
  {
    accessorKey: 'user_status',
    header: 'Holat',
    cell: ({ row }) => {
      const status = participantStatus.find(
        item => item.value === row.getValue('user_status')
      );
      return <span>{status?.title}</span>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setSheetOpen={setSheetOpen}
        setDialogOpen={setDialogOpen}
      />
    ),
  },
];
