import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getParticipantsList } from '../adapters';
import { GetParticipantsList } from '../api';

export const useParticipantsList = (currentPage: number, id: string) => {
  const initialData = {
    data: getParticipantsList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['tournament_participants_list', currentPage],
    queryFn: () => GetParticipantsList(currentPage, id),
    select: data => ({
      data: getParticipantsList(get(data, 'data.data')),
      paginationInfo: get(data, 'data.pagination'),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
