import http from 'services/api';
import { ParticipantStatusInput } from './types';

export const GetParticipantsList = async (currentPage: number, id: string) => {
  return await http.get(
    `/tournaments/${id}/tournament-participant?sort=-not_contacted&page=${currentPage}`
  );
};

export const ChangeParticipantStatus = async (
  id: string,
  values: ParticipantStatusInput
) => {
  return await http.post(`/tournaments/${id}/participant-status`, values);
};

export const DeleteParticipant = async (tournamentId: string, user: string) => {
  return await http.delete(
    `/tournaments/${tournamentId}/tournament-participant?user=${user}`
  );
};
