import http from 'services/api';
import { ModuleBot, ModuleBotEditBody, ModuleBotInput } from './types';

export const GetModuleBotList = async () => {
    return await http.get(`/uzchess-bot-module/`);
};

export const CreateModuleBot = async (values: ModuleBotInput) => {
    return await http.post<{ data: ModuleBot }>(`/uzchess-bot-module/`, values);
};

export const EditModuleBot = async ({ values, id }: ModuleBotEditBody) => {
    return await http.put(`/uzchess-bot-module/${id}`, values);
};

export const DeleteModuleBot = async (id: string) => {
    return await http.delete(`/uzchess-bot-module/${id}`);
};