import { useMutation } from '@tanstack/react-query';

import { useToast } from 'components/ui/use-toast';
import { CreateModuleBot } from '../api';
import { ModuleBotInput } from '../types';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHook {
    setSheetOpen: (state: boolean) => void;
}

export const useCreateModuleBot = ({ setSheetOpen }: IHook) => {
    const { toast } = useToast();

    const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
        mutationFn: (values: ModuleBotInput) => CreateModuleBot(values),
        onSuccess: () => {
            toast({
                variant: 'success',
                title: 'Muvaffaqiyat!',
                description: 'Module boti muvaffaqiyatli yaratildi.',
            });
            queryClient.invalidateQueries({ queryKey: ['module_bots_list'] });
            setSheetOpen(false);
        },
        onError: (error: any) => showErrorToast(error),
    });

    return {
        triggerModuleBotCreate: mutateAsync,
        isPending,
        isSuccess,
        isError,
        data,
    };
};
