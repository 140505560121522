import http from 'services/api';

export const GetRegionStatisticsExcel = async () => {
  return await http.get(`/excel/region`, { responseType: 'blob' });
};

export const GetDistrictStatisticsExcel = async () => {
  return await http.get(`/excel/district`, { responseType: 'blob' });
};

export const GetNeighborhoodStatisticsExcel = async (region: string, district: string) => {
  const params = { region, district };
  return await http.get('/excel/neighborhood', { params, responseType: 'blob' });
};

export const GetTournamentParticipantsExcel = async (tournamentId: string) => {
  return await http.get(`/excel/${tournamentId}/participants`, {
    responseType: 'blob',
  });
};
