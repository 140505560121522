import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { UzchessBot } from 'modules/uzchess-bot/types';
import { Link } from 'react-router-dom';
import normalizeImgUrl from 'utils/normalizeFileUrl';

interface IProps {
    getRowData: (uzchessBot: UzchessBot) => void;
    setSheetOpen: (state: boolean) => void;
    setDialogOpen: (state: boolean) => void;
}

export const createUzchessBotColumns = ({
    getRowData,
    setSheetOpen,
    setDialogOpen,
}: IProps): ColumnDef<UzchessBot>[] => [
        {
            accessorKey: 'full_name',
            header: "To'liq ismi",
            cell: ({ row }) => (
                <p className="leading-7 dark:text-white">{row.getValue('full_name')}</p>
            ),
        },
        {
            accessorKey: 'image',
            header: 'Rasmi',
            cell: ({ row }) => {
                const imageUrl = row.getValue<string>('image');
                return imageUrl ? (
                    <img src={normalizeImgUrl(imageUrl)} alt="img" style={{ width: 50, height: 50 }} />
                ) : (
                    <span>Rasm mavjud emas</span>
                );
            },
        },
        {
            accessorKey: 'description',
            header: 'Tavsif',
            cell: ({ row }) => (
                <div
                    dangerouslySetInnerHTML={{ __html: row.getValue<string>('description') }}
                />
            ),
        },
        {
            accessorKey: 'bot_link',
            header: 'Bot havolasi',
            cell: ({ row }) => (
                <Link
                    to={row.getValue('bot_link')}
                    className="hover:underline text-blue-500"
                    target="_blank"
                >
                    Havola
                </Link>
            ),
        },
        {
            accessorKey: 'rating',
            header: 'Reytingi',
        },
        {
            accessorKey: '_id',
            header: () => <span className="sr-only">Actions</span>,
            size: 50,
            cell: ({ row }) => (
                <DataTableRowActions
                    row={row}
                    getRowData={getRowData}
                    setDialogOpen={setDialogOpen}
                    setSheetOpen={setSheetOpen}
                />
            ),
        },
    ];