import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { GetPuzzleReports } from '../api';
import { getPuzzleReports } from '../adapters';

export const usePuzzlesReports = (id: string) => {
  const initialData = {
    data: getPuzzleReports(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['puzzle_reports', id],
    queryFn: () => GetPuzzleReports(id),
    select: data => ({
      data: getPuzzleReports(get(data, 'data.data')),
    }),
  });
  return {
    ...data,
    ...args,
  };
};
