import { FC } from 'react';
import { Download, Loader2 } from 'lucide-react';
import { useRegionStatisticsExcel } from 'modules/excel/hooks/useRegionStatisticsExcel';
import { useDistrictStatisticsExcel } from 'modules/excel/hooks/useDistrictStatisticsExcel';
import { useNeighborhoodStatisticsExcel } from 'modules/excel/hooks/useNeighborhoodStatisticsExcel';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { toast } from 'components/ui/use-toast';
import { Button } from 'components/ui/button';

interface IProps {
  regionId: string;
  districtId: string;
}

const ExcelDropdownMenu: FC<IProps> = ({ regionId, districtId }) => {
  const { triggerRegionExcel, isPending: isLoadingRegion } =
    useRegionStatisticsExcel();
  const { triggerDistrictExcel, isPending: isLoadingDistrict } =
    useDistrictStatisticsExcel();
  const { triggerNeighborhoodExcel, isPending: isLoadingNeighborhood } =
    useNeighborhoodStatisticsExcel(regionId, districtId);

  const handleNeighborhoodExcel = () => {
    if (regionId && districtId) {
      triggerNeighborhoodExcel();
    } else {
      toast({
        variant: 'destructive',
        title: 'Xatolik!',
        description: 'Iltimos, viloyatni va tumanni tanlang!',
      });
    }
  };

  const isLoading =
    isLoadingRegion || isLoadingDistrict || isLoadingNeighborhood;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="p-2 text-xs flex items-center">
          {isLoading ? (
            <>
              <Loader2 className="animate-spin size-4 mr-2" />
              <span>Yuklanmoqda...</span>
            </>
          ) : (
            <>
              <Download className="size-4" />
              <span className="ml-2">Excel</span>
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => triggerRegionExcel()}>
          Viloyat
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => triggerDistrictExcel()}>
          Tuman
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleNeighborhoodExcel}>
          Mahalla
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ExcelDropdownMenu;
