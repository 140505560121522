import { useEffect, useRef, useState } from 'react';
import { UsersCount } from 'modules/statistics/types';
import { useUsersCountByDistrict } from 'modules/statistics/hooks/useUsersCountByDistrict';
import { useUsersCountByNeighborhood } from 'modules/statistics/hooks/useUsersCountByNeighborhood';
import { DataTable } from 'components/DataTable';
import Loader from 'components/Loader';
import {
  createCountryColumns,
  createRegionColumns,
  createDistrictColumns,
  createNeighborhoodColumns,
} from './Columns';
import { useUsersCountByRegion } from 'modules/statistics/hooks/useUsersCountByRegion';
import { useUsersCountByCountry } from 'modules/statistics/hooks/useUsersCountByCountry';
import Map from './Map';

function Page() {
  const svgRef = useRef<HTMLDivElement>(null);
  const [selectedRegion, setSelectedRegion] = useState<UsersCount>();
  const [selectedDistrict, setSelectedDistrict] = useState<UsersCount>();

  const { data: countsByCountry, isPending: isLoadingCountry } =
    useUsersCountByCountry();
  const { data: countsByRegion, isPending: isLoadingRegion } =
    useUsersCountByRegion();
  const { data: countsByDistrict, isPending: isLoadingDistrict } =
    useUsersCountByDistrict(selectedRegion?._id);
  const {
    data: countsByNeighborhood,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isLoadingNeighborhood,
  } = useUsersCountByNeighborhood(selectedRegion?._id, selectedDistrict?._id);

  const selectRegion = (regionId: string) => {
    const region = countsByRegion.find(region => region._id === regionId);
    setSelectedRegion(region);
    setSelectedDistrict(undefined);
  };

  useEffect(() => {
    const dataOfTashkent = countsByRegion.find(
      region => region._id === 'Toshkent shahri'
    );

    if (!isLoadingRegion) setSelectedRegion(dataOfTashkent);
  }, [countsByRegion, isLoadingRegion]);

  const onEndReach = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <div className="space-y-6 my-6" ref={svgRef}>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="w-full lg:w-1/2">
          <Map
            selectedRegion={selectedRegion}
            selectedDistrict={selectedDistrict}
            onSelect={selectRegion}
            isLoading={isLoadingRegion}
            data={countsByRegion}
          />
        </div>
        <div className="w-full lg:w-1/2 h-[455px] overflow-auto mt-5 lg:mt-0">
          {isLoadingRegion ? (
            <Loader />
          ) : (
            <DataTable
              data={countsByRegion}
              columns={createRegionColumns}
              rowAction={(data: UsersCount) => {
                setSelectedRegion(data);
                setSelectedDistrict(undefined);
              }}
              checkRowSelected={data => {
                return data._id === selectedRegion?._id;
              }}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-[910px] md:h-[455px]">
        {isLoadingDistrict ? (
          <Loader />
        ) : (
          <DataTable
            data={countsByDistrict}
            columns={createDistrictColumns}
            rowAction={(data: UsersCount) => setSelectedDistrict(data)}
            checkRowSelected={data => {
              return data._id === selectedDistrict?._id;
            }}
          />
        )}
        {isLoadingNeighborhood ? (
          <Loader />
        ) : (
          <DataTable
            loadingNextPage={isFetchingNextPage}
            onEndReach={onEndReach}
            data={countsByNeighborhood?.data!}
            columns={createNeighborhoodColumns}
          />
        )}
      </div>
      <div className="h-[455px]">
        {isLoadingCountry ? (
          <Loader />
        ) : (
          <DataTable data={countsByCountry} columns={createCountryColumns} />
        )}
      </div>
    </div>
  );
}

export default Page;
