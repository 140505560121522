import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form, FormDescription, FormMessage } from 'components/ui/form';
import { Quiz, QuizContentType, QuizType } from 'modules/quizzes/types';
import { TextAreaField, SelectField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { quizSelectType } from 'constants/index';
import QuizOptions from './QuizOptions';
import { useParams } from 'react-router-dom';
import { useCreateQuiz } from 'modules/quizzes/hooks/useCreateQuiz';
import { useEditQuiz } from 'modules/quizzes/hooks/useEditQuiz';

const quizSchema = z.object({
  type: z.nativeEnum(QuizType, { required_error: 'Savol tipini tanlang' }),
  question: z.array(
    z.object({
      type: z.nativeEnum(QuizContentType),
      content: z.string().min(1, { message: 'Savolni kiriting' }),
    })
  ),
  options: z
    .array(
      z.object({
        value: z.array(
          z.object({
            type: z.nativeEnum(QuizContentType),
            content: z.string().min(1, { message: 'Javobni kiriting' }),
          })
        ),
        is_correct: z.boolean(),
      })
    )
    .refine(data => data.some(option => option.is_correct), {
      message: "To'g'ri javobni belgilang",
      path: ['options'],
    }),
});

export type quizFormSchema = z.infer<typeof quizSchema>;

interface IProps {
  quiz?: Quiz;
  setSheetOpen: (state: boolean) => void;
}

export default function QuizForm({ quiz, setSheetOpen }: IProps) {
  const { lessonId } = useParams();
  const { triggerQuizCreate, isPending: isQuizCreatePending } = useCreateQuiz({
    setSheetOpen,
  });
  const { triggerQuizEdit, isPending: isQuizEditPending } = useEditQuiz({
    id: quiz?._id,
    setSheetOpen,
  });

  const form = useForm<quizFormSchema>({
    resolver: zodResolver(quizSchema),
    defaultValues: quiz
      ? {
          type: quiz.type,
          question: quiz.question,
          options: quiz.options,
        }
      : {
          type: QuizType.SINGLE_SELECT,
          question: [{ type: QuizContentType.TEXT, content: '' }],
          options: [
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
          ],
        },
  });
  const {
    control,
    formState: { errors },
    getValues,
  } = form;
  const { fields: questionFields } = useFieldArray({
    name: 'question',
    control,
  });
  const quizType = getValues('type');

  function onSubmit(formValues: quizFormSchema) {
    if (quiz) {
      triggerQuizEdit({ ...formValues, lesson: lessonId! });
    } else {
      triggerQuizCreate({ ...formValues, lesson: lessonId! });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <SelectField
            name="type"
            data={quizSelectType}
            label="Savol turini tanlang"
            required
          />
          {questionFields.map((field, index) => (
            <TextAreaField
              key={field.id}
              name={`question.${index}.content`}
              label="Savol"
              required
            />
          ))}
          <hr />
          <FormDescription className="mb-2 text-xs">
            {quizType === 'single_select'
              ? "Bitta to'g'ri javobni belgilang"
              : "Bir va undan ortiq to'g'ri javobni belgilang"}
          </FormDescription>
          <QuizOptions />

          {errors.options && (
            // @ts-ignore
            <FormMessage>{errors.options.options.message}</FormMessage>
          )}
        </div>
        {quiz ? (
          <LoadingButton isLoading={isQuizEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isQuizCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
