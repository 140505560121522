import http from 'services/api';
import { PuzzleEditBody, PuzzleInput } from './types';

export const GetPuzzlesList = async (currentPage: number) => {
  return await http.get(`/puzzles/all?sort=-report_count&page=${currentPage}`);
};

export const GetPuzzleReports = async (id: string) => {
  return await http.get(`/puzzles/${id}/report`);
};

export const CreatePuzzle = async (values: PuzzleInput) => {
  return await http.post(`/puzzles/`, values);
};

export const EditPuzzle = async ({ values, id }: PuzzleEditBody) => {
  return await http.put(`/puzzles/${id}`, values);
};

export const DeletePuzzle = async (id: string) => {
  return await http.delete(`/puzzles/${id}`);
};
