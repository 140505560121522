import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/DataTable';
import { TournamentParticipant } from 'modules/tournament-participants/types';
import { useParticipantsList } from 'modules/tournament-participants/hooks/useParticipantsList';
import { useDeleteParticipant } from 'modules/tournament-participants/hooks/useDeleteParticipant';
import Loader from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { AlertDialog } from 'components/AlertDialog';
import { Sheet } from 'components/Sheet';
import { createParticipantColumns } from './Columns';
import ParticipantForm from './ParticipantForm';
import { useTournamentParticipantsExcel } from 'modules/excel/hooks/useTournamentParticipantsExcel';
import LoadingButton from 'components/LoadingButton';
import { Download } from 'lucide-react';

const TournamentParticipants = () => {
  const { tournamentId } = useParams();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [participant, setParticipant] = useState<TournamentParticipant>();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: participantsList,
    paginationInfo,
    isLoading,
  } = useParticipantsList(currentPage, tournamentId!);
  const { triggerParticipantDelete } = useDeleteParticipant(
    tournamentId!,
    participant?._id!
  );
  const { triggerParicipantsExcel, isPending: isLoadingExcel } =
    useTournamentParticipantsExcel(tournamentId!);

  const getRowData = (participant: TournamentParticipant) => {
    setParticipant(participant);
  };

  const columns = createParticipantColumns({
    getRowData,
    setSheetOpen,
    setDialogOpen,
  });

  return (
    <div>
      <div className="flex w-full mb-4">
        <LoadingButton
          className="ml-auto p-2 text-xs"
          variant="outline"
          onClick={triggerParicipantsExcel}
          isLoading={isLoadingExcel}
        >
          <Download className="size-4 stroke-[1.3px]" />
          Musobaqa ishtirokchilari
        </LoadingButton>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={participantsList} />
          <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          />
        </>
      )}

      <Sheet
        sheetTitle="Ishtirokchi haqida ma'lumot"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <ParticipantForm
          participant={participant!}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerParticipantDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default TournamentParticipants;
