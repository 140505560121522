import { useMutation } from '@tanstack/react-query';

import { useToast } from 'components/ui/use-toast';
import { ChangeParticipantStatus } from '../api';
import { ParticipantStatusInput } from '../types';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHook {
  id: string;
  setSheetOpen: (state: boolean) => void;
}

export const useChangeParticipantStatus = ({ id, setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: ParticipantStatusInput) =>
      ChangeParticipantStatus(id, values),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: "Ishtirokchi holati muvaffaqiyatli o'zgartirildi.",
      });
      queryClient.invalidateQueries({
        queryKey: ['tournament_participants_list'],
      });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerStatusChange: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
