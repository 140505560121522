import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'services/react-query';
import { DeleteParticipant } from '../api';
import { useToast } from 'components/ui/use-toast';
import { showErrorToast } from 'utils/showErrorToast';

export const useDeleteParticipant = (
  tournamentId: string,
  participantId: string
) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DeleteParticipant(tournamentId, participantId),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({
        queryKey: ['tournament_participants_list'],
      });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return { triggerParticipantDelete: mutate, isSuccess, isError };
};
