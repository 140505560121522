import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';

import { ModuleBotInput } from '../types';
import { EditModuleBot } from '../api';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHook {
    id?: string;
    setSheetOpen: (state: boolean) => void;
}

export const useEditModuleBot = ({ id = '', setSheetOpen }: IHook) => {
    const { toast } = useToast();

    const { mutate, isPending, isSuccess, isError } = useMutation({
        mutationFn: (values: ModuleBotInput) =>
            EditModuleBot({ values, id }),
        onSuccess: () => {
            toast({
                variant: 'success',
                title: 'Muvaffaqiyat!',
                description: 'Module boti muvaffaqiyatli tahrirlandi.',
            });
            queryClient.invalidateQueries({ queryKey: ['module_bots_list'] });
            setSheetOpen(false);
        },
        onError: (error: any) => showErrorToast(error),
    });

    return {
        triggerModuleBotEdit: mutate,
        isPending,
        isSuccess,
        isError,
    };
};
