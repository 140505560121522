import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { ModuleBot } from 'modules/module-bot/types';

interface IProps {
  getRowData: (moduleBots: ModuleBot) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createModuleBotColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<ModuleBot>[] => [
  {
    accessorKey: 'name',
    header: 'Module boti nomi',
    cell: ({ row }) => (
      <p className="leading-7 dark:text-white">{row.getValue('name')}</p>
    ),
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
