import {
  Address,
  PariticipantStatus,
  ParticipantLevel,
  Profile,
  TournamentParticipant,
  User,
} from './types';

export const getParticipant = (item?: TournamentParticipant) => {
  return {
    _id: item?._id ?? '',
    dob: item?.dob ?? '',
    user_status: item?.user_status ?? PariticipantStatus.NOT_CONTACTED,
    level: item?.level ?? ParticipantLevel.AMATEUR,
    user: getUser(item?.user),
    profile: getProfile(item?.profile),
  };
};

export const getParticipantsList = (data?: TournamentParticipant[]) => {
  return data?.length
    ? data.map(item => {
        return getParticipant(item);
      })
    : [];
};

function getUser(item?: User) {
  return {
    _id: item?._id ?? '',
    phone_number: item?.phone_number ?? '',
  };
}

function getProfile(item?: Profile) {
  return {
    _id: item?._id ?? '',
    first_name: item?.first_name ?? '',
    last_name: item?.last_name ?? '',
    address: getProfileAddress(item?.address),
  };
}

function getProfileAddress(item?: Address) {
  return {
    country: item?.country ?? '',
    region: item?.region ?? '',
    district: item?.district ?? '',
    neighborhood: item?.neighborhood ?? '',
  };
}
