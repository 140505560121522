import http from 'services/api';
import { CourseEditBody, CourseInput } from './types';

export const GetCoursesList = async () => {
  return await http.get(`/courses/`);
};

export const CreateCourse = async (values: CourseInput) => {
  return await http.post(`/courses/`, values);
};

export const EditCourse = async ({ values, id }: CourseEditBody) => {
  return await http.put(`/courses/${id}`, values);
};

export const DeleteCourse = async (id: string) => {
  return await http.delete(`/courses/${id}`);
};
