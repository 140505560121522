import { ColumnDef } from '@tanstack/react-table';
import { Module } from 'modules/modules/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { timeConverter } from 'utils/timeConverter';

interface IProps {
  getRowData: (module: Module) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createModuleColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Module>[] => [
  {
    accessorKey: 'name',
    header: "Bo'lim nomi",
  },
  {
    accessorKey: 'duration',
    header: 'Davomiyligi',
    size: 140,
    cell: ({ row }) => {
      const duration = row.getValue('duration') as number;
      return <span>{timeConverter(duration)}</span>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
