import { GrandmasterBot } from './types';
export const getGrandmasterBot = (item?: GrandmasterBot) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    description: item?.description ?? '',
    image: item?.image ?? '',
    link: item?.link ?? '',
  };
};

export const getGrandmasterBotsList = (data?: GrandmasterBot[]) => {
  return data?.length
    ? data.map(item => {
        return getGrandmasterBot(item);
      })
    : [];
};
