import http from 'services/api';
import { ModuleEditBody, ModuleCreateInput } from './types';

export const GetModulesList = async (courseId: string) => {
  return await http.get(`/courses/${courseId}/modules/`);
};

export const CreateModule = async (values: ModuleCreateInput) => {
  return await http.post(`/modules/`, values);
};

export const EditModule = async ({ values, id }: ModuleEditBody) => {
  return await http.put(`/modules/${id}`, values);
};

export const DeleteModule = async (id: string) => {
  return await http.delete(`/modules/${id}`);
};
