import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Skeleton } from 'components/ui/skeleton';
import { usePuzzlesReports } from 'modules/puzzles/hooks/usePuzzleReports';

interface IProps {
  id: string;
  isReportOpen: boolean;
  setReportOpen: (state: boolean) => void;
}

export default function PuzzleReport({
  id,
  isReportOpen,
  setReportOpen,
}: IProps) {
  const { data, isLoading } = usePuzzlesReports(id);

  return (
    <Dialog open={isReportOpen} onOpenChange={setReportOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Foydalanuvchidan kelgan xabar</DialogTitle>
          <DialogDescription className="flex flex-col gap-2">
            {isLoading ? (
              <Skeleton className="w-56 h-8" />
            ) : (
              data.map(item => (
                <p className="p-2 bg-secondary rounded-md" key={item._id}>
                  {item.body}
                </p>
              ))
            )}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
