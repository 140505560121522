import { ModuleBot } from './types';
export const getModuleBot = (item?: ModuleBot) => {
    return {
        _id: item?._id ?? '',
        name: item?.name ?? '',
        description: item?.description ?? '',
    };
};

export const getModuleBotsList = (data?: ModuleBot[]) => {
    return data?.length
        ? data.map(item => {
            return getModuleBot(item);
        })
        : [];
};