import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getLessonsList } from '../adapters';
import { GetLessonsList } from '../api';

export const useLessonsList = (moduleId: string, currentPage: number) => {
  const initialData = {
    data: getLessonsList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['lessons_list', moduleId, currentPage],
    queryFn: () => GetLessonsList(moduleId, currentPage),
    select: data => {
      const lessonsList = getLessonsList(get(data, 'data.data'));
      const sortedLessonsList = lessonsList.sort((a, b) => a.order - b.order);

      return {
        data: sortedLessonsList,
        paginationInfo: get(data, 'data.pagination'),
      };
    },
  });

  return {
    ...data,
    ...args,
  };
};
