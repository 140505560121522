import { z } from 'zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { useCreateLesson } from 'modules/lessons/hooks/useCreateLesson';
import { useEditLesson } from 'modules/lessons/hooks/useEditLesson';
import { Form } from 'components/ui/form';
import { Lesson } from 'modules/lessons/types';
import { RichTextEditor, TextField, TimePickerField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { convertSecondsToHMS } from 'utils/timeConverter';

const lessonSchema = z.object({
  name: z.string().min(3),
  description: z.string().min(20),
  youtube_link: z.string().url(),
  download_link: z.string().url(),
  duration: z.number(),
  _duration: z.date().optional(),
});

type lessonFormSchema = z.infer<typeof lessonSchema>;

interface IProps {
  lesson?: Lesson;
  lastDataOrder?: number;
  setSheetOpen: (state: boolean) => void;
}

const initialDate = new Date();
initialDate.setHours(0, 0, 0);

export default function LessonForm({
  lesson,
  lastDataOrder: lastLessonOrder,
  setSheetOpen,
}: IProps) {
  const { moduleId } = useParams();
  const { triggerLessonCreate, isPending: isLessonCreatePending } =
    useCreateLesson({ setSheetOpen });
  const { triggerLessonEdit, isPending: isLessonEditPending } = useEditLesson({
    id: lesson?._id,
    setSheetOpen,
  });

  const _duration = useMemo(() => {
    const value = new Date(initialDate);
    if (!lesson?.duration) return value;
    const { hours, minutes, seconds } = convertSecondsToHMS(lesson.duration);

    value.setHours(hours, minutes, seconds);
    return value;
  }, [lesson?.duration]);

  const form = useForm<lessonFormSchema>({
    resolver: zodResolver(lessonSchema),
    defaultValues: lesson
      ? {
          name: lesson.name,
          description: lesson.description,
          youtube_link: lesson.youtube_link,
          download_link: lesson.download_link,
          duration: lesson.duration,
          _duration,
        }
      : {
          name: '',
          description: '',
          youtube_link: '',
          download_link: '',
          duration: 0,
          _duration,
        },
  });

  function onSubmit(formValues: lessonFormSchema) {
    const duration = formValues._duration
      ? (formValues._duration.getTime() - new Date(initialDate).getTime()) /
        1000
      : 0;
    formValues.duration = duration;
    delete formValues._duration;

    if (lesson) {
      triggerLessonEdit(formValues);
    } else {
      triggerLessonCreate({
        ...formValues,
        module: moduleId as string,
        order: lastLessonOrder ? lastLessonOrder + 1 : 1,
      });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Dars nomi" required />
          <TextField name="youtube_link" label="YouTube havolasi" required />
          <TextField
            name="download_link"
            label="Yuklab olish havolasi"
            required
          />
          <TimePickerField
            name="_duration"
            label="Darsning davomiyligi"
            required
          />
          <RichTextEditor name="description" label="Dars tavsifi" required />
        </div>
        {lesson ? (
          <LoadingButton isLoading={isLessonEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isLessonCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
