import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getTournamentsList } from '../adapters';
import { GetTournamentsList } from '../api';

export const useTournamentsList = (currentPage: number) => {
  const initialData = {
    data: getTournamentsList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['tournaments_list', currentPage],
    queryFn: () => GetTournamentsList(currentPage),
    select: data => ({
      data: getTournamentsList(get(data, 'data.data')),
      paginationInfo: get(data, 'data.pagination'),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
