import http from 'services/api';
import { DateRange } from 'react-day-picker';
import { PuzzleDifficulty } from 'modules/puzzles/types';
import { GetUsersCountNeighborhood, UsersCount } from './types';

export const GetOverview = async () => {
  return await http.get(`/statistics/overview`);
};

export const GetNewUsers = async (date?: DateRange) => {
  return await http.get('/statistics/daily-user-count', {
    params: {
      start_date: date?.from,
      end_date: date?.to,
    },
  });
};

export const GetDailyPuzzleSubmission = async (
  puzzleType?: PuzzleDifficulty,
  date?: DateRange
) => {
  return await http.get('/statistics/daily-puzzle-submission', {
    params: {
      puzzle_type: puzzleType,
      start_date: date?.from,
      end_date: date?.to,
    },
  });
};

export const GetUsersCountByCountry = async () => {
  return await http.get<UsersCount[]>(`/statistics/user-countries`);
};

export const GetUsersCountByArea = async (
  regionId?: string,
  districtId?: string
) => {
  return await http.get<UsersCount[]>('/statistics/count-by-area', {
    params: {
      region: regionId,
      district: districtId,
    },
  });
};

export const GetUsersCountByNeighborhood = async (
  pageParam?: number,
  regionId?: string,
  districtId?: string
) => {
  return await http.get<GetUsersCountNeighborhood>(
    '/statistics/count-by-neighborhood',
    {
      params: {
        page: pageParam,
        region: regionId,
        district: districtId,
      },
    }
  );
};
