import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'services/react-query';
import { DeleteModuleBot } from '../api';
import { useToast } from 'components/ui/use-toast';
import { showErrorToast } from 'utils/showErrorToast';

export const useDeleteModuleBot = (id: string) => {
    const { toast } = useToast();

    const { mutate, isSuccess, isError } = useMutation({
        mutationFn: () => DeleteModuleBot(id),
        onSuccess: () => {
            toast({
                variant: 'success',
                title: "Muvaffaqiyatli o'chirildi!",
            });
            queryClient.invalidateQueries({ queryKey: ['module_bots_list'] });
        },
        onError: (error: any) => showErrorToast(error),
    });

    return { triggerModuleBotDelete: mutate, isSuccess, isError };
};
