import { useState } from 'react';
import { Tournament } from 'modules/tournaments/types';
import { DataTable } from 'components/DataTable';
import { useTournamentsList } from 'modules/tournaments/hooks/useTournamentsList';
import { useDeleteTournament } from 'modules/tournaments/hooks/useDeleteTournament';
import Loader from 'components/Loader';
import { TableActions } from 'components/TableActions';
import { AlertDialog } from 'components/AlertDialog';
import { Sheet } from 'components/Sheet';
import TournamentForm from './TournamentForm';
import { createTournamentColumns } from './Columns';
import { Pagination } from 'components/Pagination';

const Tournaments = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [tournament, setTournament] = useState<Tournament>();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: tournamentsList,
    paginationInfo,
    isLoading,
  } = useTournamentsList(currentPage);
  const { triggerTournamentDelete } = useDeleteTournament(tournament?._id!);

  const getRowData = (tournament: Tournament) => {
    setTournament(tournament);
  };

  const columns = createTournamentColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Musobaqa qo'shish"
        sheetTitle="Yangi musobaqa qo'shish."
        TableForm={TournamentForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={tournamentsList} navigateTable />
          <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          />
        </>
      )}

      <Sheet
        sheetTitle="Musobaqani tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <TournamentForm tournament={tournament} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerTournamentDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Tournaments;
