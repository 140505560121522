import http from 'services/api';
import { LessonCreateInput, LessonEditBody } from './types';

export const GetLessonsList = async (moduleId: string, currentPage: number) => {
  return await http.get(
    `/modules/${moduleId}/lessons-admin?sort=created_at&page=${currentPage}`
  );
};

export const CreateLesson = async (values: LessonCreateInput) => {
  return await http.post(`/lessons/`, values);
};

export const EditLesson = async ({ values, id }: LessonEditBody) => {
  return await http.put(`/lessons/${id}`, values);
};

export const DeleteLesson = async (id: string) => {
  return await http.delete(`/lessons/${id}`);
};
