import * as React from 'react';
import { Skeleton } from 'components/ui/skeleton';
import formatNumber from 'utils/formatNumber';
import { UsersCount } from 'modules/statistics/types';
import { MapSVG } from 'assets/map';
import ExcelDropdownMenu from './ExcelDropdownMenu';

interface IProps {
  selectedRegion?: UsersCount;
  selectedDistrict?: UsersCount;
  onSelect: (id: string) => void;
  isLoading: boolean;
  data: UsersCount[];
}

const Map: React.FC<IProps> = ({
  selectedRegion,
  selectedDistrict,
  onSelect,
  isLoading,
  data,
}) => {
  const regionId = selectedRegion?._id || '';
  const districtId = selectedDistrict?._id || '';

  return (
    <div className="flex relative flex-col items-center">
      <div className="w-full sm:w-[250px] py-3 px-4 bg-secondary rounded-md mb-4 md:absolute md:top-0 md:right-0">
        <div className="text-lg font-semibold mb-3">
          {isLoading ? (
            <Skeleton className="h-7 w-32 bg-secondary-foreground/10" />
          ) : (
            regionId
          )}
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col justify-between flex-1">
            <p className="text-xs text-dark/60 font-medium mb-1">
              Foydalanuvchilar soni
            </p>
            <div className="text-lg font-bold">
              {isLoading ? (
                <Skeleton className="h-7 w-20 bg-secondary-foreground/10" />
              ) : (
                formatNumber(selectedRegion?.profiles)
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between flex-1">
            <p className="text-xs text-dark/60 font-medium mb-1">
              Bajarilgan boshqotirmalar
            </p>
            <div className="text-lg font-bold">
              {isLoading ? (
                <Skeleton className="h-7 w-20 bg-secondary-foreground/10" />
              ) : (
                formatNumber(selectedRegion?.submissions)
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <MapSVG
          selectedRegion={selectedRegion}
          onSelect={onSelect}
          data={data}
        />
      </div>

      <div className="flex justify-between w-full gap-2 md:absolute md:right-0 md:bottom-0 ">
        <div className="flex items-center gap-2 bg-secondary py-1 px-4 rounded-md text-sm">
          <span>Passiv</span>
          <div className="bg-gradient-to-r from-[#BFE2FF] to-[#2BA0FF] h-2 w-[110px]" />
          <span>Aktiv</span>
        </div>

        <ExcelDropdownMenu regionId={regionId} districtId={districtId} />
      </div>
    </div>
  );
};

export default Map;
