import { useState } from 'react';
import { Sheet } from 'components/Sheet';
import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import { AlertDialog } from 'components/AlertDialog';
import { createModuleBotColumns } from './Columns';
import ModuleBotForm from './ModuleBotsForm';
import Loader from 'components/Loader';
import { useModuleBotsList } from 'modules/module-bot/hooks/useModuleBotList';
import { ModuleBot } from 'modules/module-bot/types';
import { useDeleteModuleBot } from 'modules/module-bot/hooks/useDeleteModuleBot';

const ModuleBots = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [moduleBot, setModuleBot] = useState<ModuleBot>();

  const { data: ModuleBotsList, isLoading } = useModuleBotsList();
  const { triggerModuleBotDelete } = useDeleteModuleBot(moduleBot?._id!);

  const getRowData = (moduleBots: ModuleBot) => {
    setModuleBot(moduleBots);
  };

  const columns = createModuleBotColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Module botini qo'shish"
        sheetTitle="Yangi module bot qo'shish."
        TableForm={ModuleBotForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={ModuleBotsList} navigateTable />
      )}

      <Sheet
        sheetTitle="Module botini tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <ModuleBotForm moduleBot={moduleBot} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerModuleBotDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default ModuleBots;
