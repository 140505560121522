import { NotificationType } from 'modules/notifications/types';
import { PuzzleDifficulty } from 'modules/puzzles/types';
import { QuizContentType, QuizType } from 'modules/quizzes/types';
import { PariticipantStatus } from 'modules/tournament-participants/types';
import { TournamentFormat } from 'modules/tournaments/types';

export const quizSelectType = [
  {
    name: 'Bir tanlovli',
    type: QuizType.SINGLE_SELECT,
  },
  {
    name: "Ko'p tanlovli",
    type: QuizType.MULTI_SELECT,
  },
  {
    name: 'Qisqa matn tanlovli',
    type: QuizType.SHORT_TEXT,
    disabled: true,
  },
  {
    name: "Ko'p matn tanlovli",
    type: QuizType.LONG_TEXT,
    disabled: true,
  },
  {
    name: 'Mos kelish tanlovli',
    type: QuizType.MATCH_PAIRS,
    disabled: true,
  },
  {
    name: 'Ketma-ket',
    type: QuizType.SEQUENCE,
    disabled: true,
  },
];

export const contentType = [
  {
    name: 'Text format',
    type: QuizContentType.TEXT,
  },
  {
    name: 'Rasm format',
    type: QuizContentType.PHOTO,
    disabled: true,
  },
  {
    name: 'Audio format',
    type: QuizContentType.AUDIO,
    disabled: true,
  },
];

export const puzzleDifficulties = [
  {
    name: 'Oson',
    type: PuzzleDifficulty.EASY,
  },
  {
    name: "O'rta",
    type: PuzzleDifficulty.MEDIUM,
  },
  {
    name: 'Qiyin',
    type: PuzzleDifficulty.HARD,
  },
];

export const notificationType = [
  {
    name: 'Kurs',
    type: NotificationType.COURSE,
  },
  {
    name: "Bo'lim",
    type: NotificationType.MODULE,
  },
  {
    name: 'Afisha',
    type: NotificationType.AFISHA,
  },
  {
    name: 'Kitob',
    type: NotificationType.BOOK,
  },
  {
    name: 'Grandmaster',
    type: NotificationType.GRANDMASTER,
  },
  {
    name: 'Jonli efir',
    type: NotificationType.LIVE,
  },
  {
    name: 'Yangilik',
    type: NotificationType.NEWS,
  },
  {
    name: 'Tahlil',
    type: NotificationType.REVIEW,
  },
];

export const tournamentFormats = [
  {
    name: 'Offline',
    type: TournamentFormat.OFFLINE,
  },
  {
    name: 'Online',
    type: TournamentFormat.ONLINE,
  },
];

export const participantStatus = [
  {
    title: "Qo'ng'iroq qilindi",
    value: PariticipantStatus.CONTACTED,
  },
  {
    title: "Qo'ng'iroq qilinmadi",
    value: PariticipantStatus.NOT_CONTACTED,
  },
];
