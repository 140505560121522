import { useState } from 'react';
import { useGrandmasterBotsList } from 'modules/grandmasters-bot/hooks/useGrandmasterBotsList';
import { useDeleteGrandmasterBot } from 'modules/grandmasters-bot/hooks/useDeleteGrandmasterBot';
import { GrandmasterBot } from 'modules/grandmasters-bot/types';
import { Sheet } from 'components/Sheet';
import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import { AlertDialog } from 'components/AlertDialog';
import { createGrandmasterBotColumns } from './Columns';
import GrandmasterBotForm from './GrandmasterBotForm';
import Loader from 'components/Loader';

const GrandmasterBots = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [grandmasterBot, setGrandmasterBot] = useState<GrandmasterBot>();

  const { data: grandmasterBotsList, isLoading } = useGrandmasterBotsList();
  const { triggerGrandmasterBotDelete } = useDeleteGrandmasterBot(
    grandmasterBot?._id!
  );

  const getRowData = (grandmasterBots: GrandmasterBot) => {
    setGrandmasterBot(grandmasterBots);
  };

  const columns = createGrandmasterBotColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Grandmaster botini qo'shish"
        sheetTitle="Yangi grandmasterBot qo'shish."
        TableForm={GrandmasterBotForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={grandmasterBotsList} />
      )}

      <Sheet
        sheetTitle="Grandmaster botini tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <GrandmasterBotForm
          grandmasterBot={grandmasterBot}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerGrandmasterBotDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default GrandmasterBots;
