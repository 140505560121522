import { ColumnDef } from '@tanstack/react-table';
import { Puzzle } from 'modules/puzzles/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { puzzleDifficulties } from 'constants/index';

interface IProps {
  getRowData: (puzzle: Puzzle) => void;
  getRowId: (id: string) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
  setReportOpen: (state: boolean) => void;
}

export const createPuzzleColumns = ({
  getRowData,
  getRowId,
  setSheetOpen,
  setDialogOpen,
  setReportOpen,
}: IProps): ColumnDef<Puzzle>[] => [
  {
    accessorKey: 'index',
    header: 'ID',
  },
  {
    accessorKey: 'puzzle_type',
    header: 'Boshqotirma darajasi',
    cell: ({ row }) => {
      const difficulty = puzzleDifficulties.find(
        item => item.type === row.getValue('puzzle_type')
      );
      return <span>{difficulty?.name}</span>;
    },
  },
  {
    accessorKey: 'report_count',
    header: 'Xabarlar soni',
    cell: ({ row }) => (
      <span
        className="px-3 py-2 bg-secondary border rounded-md cursor-pointer"
        onClick={() => {
          setReportOpen(true);
          getRowId(row.getValue('_id'));
        }}
      >
        {row.getValue('report_count')}
      </span>
    ),
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
