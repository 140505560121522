import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getGrandmasterBotsList } from '../adapters';
import { GetGrandmasterBotsList } from '../api';

export const useGrandmasterBotsList = () => {
  const initialData = {
    data: getGrandmasterBotsList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['grandmaster_bots_list'],
    queryFn: () => GetGrandmasterBotsList(),
    select: data => ({
      data: getGrandmasterBotsList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
