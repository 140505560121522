import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Module } from 'modules/modules/types';

import { Form } from 'components/ui/form';
import { TextAreaField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { useParams } from 'react-router-dom';
import { useCreateModule } from 'modules/modules/hooks/useCreateModule';
import { useEditModule } from 'modules/modules/hooks/useEditModule';

const moduleSchema = z.object({
  name: z.string().min(3),
  description: z.string().min(3),
});

type moduleFormSchema = z.infer<typeof moduleSchema>;

interface IProps {
  module?: Module;
  lastDataOrder?: number;
  setSheetOpen: (state: boolean) => void;
}

export default function ModuleForm({
  module,
  lastDataOrder: lastModuleOrder,
  setSheetOpen,
}: IProps) {
  const { courseId } = useParams();
  const { triggerModuleCreate, isPending: isModuleCreatePending } =
    useCreateModule({ setSheetOpen });
  const { triggerModuleEdit, isPending: isModuleEditPending } = useEditModule({
    id: module?._id,
    setSheetOpen,
  });

  const form = useForm<moduleFormSchema>({
    resolver: zodResolver(moduleSchema),
    defaultValues: module
      ? {
          name: module.name,
          description: module.description,
        }
      : {
          name: '',
          description: '',
        },
  });

  async function onSubmit(formValues: moduleFormSchema) {
    if (module) {
      triggerModuleEdit(formValues);
    } else {
      triggerModuleCreate({
        ...formValues,
        course: courseId as string,
        order: lastModuleOrder ? lastModuleOrder + 1 : 1,
      });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Bo'lim nomi" required />
          <TextAreaField name="description" label="Bo'lim tavsifi" required />
        </div>
        {module ? (
          <LoadingButton isLoading={isModuleEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isModuleCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
