import { Course } from './types';
export const getCourse = (item?: Course) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    short_description: item?.short_description ?? '',
    icon: item?.icon ?? '',
    lessons_count: item?.lessons_count ?? 0,
    completed_lessons_count: item?.completed_lessons_count ?? 0,
    is_enrolled: item?.is_enrolled ?? false,
    duration: item?.duration ?? 0,
  };
};

export const getCoursesList = (data?: Course[]) => {
  return data?.length
    ? data.map(item => {
        return getCourse(item);
      })
    : [];
};
