export interface Tournament {
  _id: string;
  name: string;
  price: number;
  tournament_type: string[];
  tournament_date: string;
  registration_date: string;
  format: TournamentFormat;
  location: Location;
  image: string;
}

export interface TournamentInput extends Omit<Tournament, '_id'> {}

export interface TournamentEditBody {
  id: string;
  values: TournamentInput;
}

export enum TournamentFormat {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export interface Location {
  name: string;
  address: string;
}
