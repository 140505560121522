import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { FileField, RichTextEditor, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import useFileUploader from 'hooks/useFileUploader';
import { UzchessBot, UzchessBotInput } from 'modules/uzchess-bot/types';
import { useCreateUzchessBot } from 'modules/uzchess-bot/hooks/useCreateUzchessBot';
import { useEditUzchessBot } from 'modules/uzchess-bot/hooks/useEditUzchessBot';
import { useParams } from 'react-router-dom';

const UzchessBotSchema = z.object({
    full_name: z.string().min(3, { message: 'Familya, ism talab qilinadi' }),
    description: z.string().min(3, { message: 'Tavsif talab qilinadi' }),
    bot_link: z.string().url({ message: "Noto'g'ri link kiritilgan" }),
    image: z.union([
        z.custom<File>(file => file instanceof File, {
            message: 'Rasm talab qilinadi',
        }),
        z.string().min(1, { message: 'Rasm talab qilinadi' }),
    ]),
    rating: z.union([
        z.string({ required_error: "Reyting talab qilinadi!" }).min(1, {
            message: 'Kamida 1ta son talab qilinadi'
        }),
        z.number().min(1, { message: 'Kamida 1ta son talab qilinadi' })]),
});

type UzchessBotFormSchema = z.infer<typeof UzchessBotSchema>;

interface IProps {
    uzchessBot?: UzchessBot;
    setSheetOpen: (state: boolean) => void;
}

export default function UzchessBotForm({ uzchessBot, setSheetOpen }: IProps) {
    const { id } = useParams()

    const { uploadFile } = useFileUploader();
    const {
        triggerUzchessBotCreate,
        isPending: isUzchessBotCreatePending,
    } = useCreateUzchessBot();
    const { triggerUzchessBotEdit, isPending: isUzchessBotEditPending } =
        useEditUzchessBot({
            id: uzchessBot?._id,
            setSheetOpen,
        });

    const form = useForm<UzchessBotFormSchema>({
        resolver: zodResolver(UzchessBotSchema),
        defaultValues: uzchessBot
            ? {
                full_name: uzchessBot.full_name,
                description: uzchessBot.description,
                bot_link: uzchessBot.bot_link,
                image: uzchessBot.image,
                rating: uzchessBot.rating
            }
            : {
                full_name: '',
                description: '',
                bot_link: '',
                rating: undefined,
                image: undefined,
            },
    });

    async function onSubmit(formValues: UzchessBotFormSchema) {
        const values = await uploadFile<UzchessBotInput>(formValues, 'image');

        if (uzchessBot) {
            triggerUzchessBotEdit({ ...values, rating: Number(values.rating), bot_module: id! });
        } else {
            await triggerUzchessBotCreate({ ...values, rating: Number(values.rating), bot_module: id! });
        }
    }

    return (
        <>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="flex flex-col gap-2"
                >
                    <div className="flex gap-4 flex-col my-4">
                        <TextField name="full_name" label="To'liq ismi" required />
                        <RichTextEditor name="description" label="Tavsif" required />
                        <TextField name="bot_link" label="Bot havolasi" required />
                        <FileField name="image" label="Rasmi" required />
                        <TextField name="rating" label="Bot reytingi" type="number" required />

                    </div>
                    {uzchessBot ? (
                        <LoadingButton isLoading={isUzchessBotEditPending}>
                            Tahrirlash
                        </LoadingButton>
                    ) : (
                        <LoadingButton isLoading={isUzchessBotCreatePending}>
                            Saqlash
                        </LoadingButton>
                    )}
                </form>
            </Form>
        </>
    );
}
