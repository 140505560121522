import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { GetUsersCountByNeighborhood } from '../api';
import { get } from 'lodash';
import { getUsersCountList } from '../adapter';

export const useUsersCountByNeighborhood = (
  regionId?: string,
  districtId?: string
) => {
  const initialData = {
    data: getUsersCountList(),
  };

  const {
    data = initialData,
    fetchNextPage,
    hasNextPage,
    ...args
  } = useInfiniteQuery({
    queryKey: ['users_count_by_neighborhood', regionId, districtId],
    queryFn: ({ pageParam }) =>
      GetUsersCountByNeighborhood(pageParam, regionId, districtId),
    select: data => ({
      data: getUsersCountList(
        data.pages.flatMap(data => get(data, 'data.data')!)
      ),
    }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const { current_page, total_pages } = lastPage.data.pagination;
      return current_page < total_pages ? current_page + 1 : undefined;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    ...args,
  };
};
