import { News } from './types';
export const getNewsItem = (item?: News) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    content: item?.content ?? '',
    short_description: item?.short_description ?? '',
    image: item?.image ?? '',
    date: item?.date ?? '',
  };
};

export const getNewsList = (data?: News[]) => {
  return data?.length
    ? data.map(item => {
        return getNewsItem(item);
      })
    : [];
};
