import { UzchessBot } from './types';
export const getUzchessBot = (item?: UzchessBot) => {
    return {
        _id: item?._id ?? '',
        full_name: item?.full_name ?? '',
        description: item?.description ?? '',
        image: item?.image ?? '',
        bot_link: item?.bot_link ?? '',
        rating: item?.rating ?? 0,
    };
};

export const getUzchessBotsList = (data?: UzchessBot[]) => {
    return data?.length
        ? data.map(item => {
            return getUzchessBot(item);
        })
        : [];
};