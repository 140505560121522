import { ColumnDef } from '@tanstack/react-table';
import { Tournament } from 'modules/tournaments/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { convertDate } from 'utils/time';
import { tournamentFormats } from 'constants/index';
import { formatPrice } from 'utils/formatPrice';

interface IProps {
  getRowData: (tournament: Tournament) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createTournamentColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Tournament>[] => [
  {
    accessorKey: 'name',
    header: 'Turnir nomi',
  },
  {
    accessorKey: 'format',
    header: 'Musobaqa formati',
    cell: ({ row }) => {
      const format = tournamentFormats.find(
        item => item.type === row.getValue('format')
      );
      return <span>{format?.name}</span>;
    },
  },
  {
    accessorKey: 'price',
    header: 'Narxi',
    cell: ({ row }) => {
      return <div>{formatPrice(row.getValue('price'))}</div>;
    },
  },
  {
    accessorKey: 'tournament_date',
    header: 'Vaqti',
    cell: ({ row }) => {
      return <div>{convertDate(row.getValue('tournament_date'))}</div>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
