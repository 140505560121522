import { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { News, NewsInput } from 'modules/news/types';
import { NotificationType } from 'modules/notifications/types';

import useFileUploader from 'hooks/useFileUploader';
import { useCreateNews } from 'modules/news/hooks/useCreateNews';
import { useEditNews } from 'modules/news/hooks/useEditNews';
import { useCreateNotification } from 'modules/notifications/hooks/useCreateNotification';
import { Form } from 'components/ui/form';
import { FileField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { RichTextEditor } from 'components/fields';
import { AlertDialog } from 'components/AlertDialog';
import NotificationSwitch from 'components/NotificationSwitch';
import removeHtmlTags from 'utils/removeHtmlTags';

const newsSchema = z.object({
  title: z.string().min(3, { message: 'Yanglik nomi talab qilinadi' }),
  content: z.string().min(3, { message: 'Tavsif talab qilinadi' }),
  short_description: z
    .string()
    .min(3, { message: 'Qisqacha tavsif talab qilinadi' }),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type newsFormSchema = z.infer<typeof newsSchema>;

interface IProps {
  news?: News;
  setSheetOpen: (state: boolean) => void;
}

export default function NewsForm({ news, setSheetOpen }: IProps) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isNotified, setIsNotified] = useState(false);
  const { triggerNotificationCreate } = useCreateNotification({
    setSheetOpen: () => {},
  });

  const { uploadFile } = useFileUploader();
  const {
    triggerNewsCreate,
    isPending: isNewsCreatePending,
    data,
  } = useCreateNews();
  const { triggerNewsEdit, isPending: isNewsEditPending } = useEditNews({
    id: news?._id,
    setSheetOpen,
  });

  const form = useForm<newsFormSchema>({
    resolver: zodResolver(newsSchema),
    defaultValues: news
      ? {
          title: news.title,
          short_description: news.short_description,
          content: news.content,
          image: news.image,
        }
      : {
          title: '',
          short_description: '',
          content: '',
          image: undefined,
        },
  });

  const sendNotification = async () => {
    const news = data?.data?.data;
    if (news) {
      await triggerNotificationCreate({
        title: news.title,
        body: removeHtmlTags(news.content),
        image: news.image,
        type: NotificationType.NEWS,
        entity_id: news._id,
      });
      setSheetOpen(false);
    }
  };

  async function onSubmit(formValues: newsFormSchema) {
    const values = await uploadFile<NewsInput>(formValues, 'image');

    if (news) {
      triggerNewsEdit(values);
    } else {
      await triggerNewsCreate(values);
      if (isNotified) {
        setDialogOpen(true);
      } else {
        setSheetOpen(false);
      }
    }
  }
  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          <div className="flex gap-4 flex-col my-4">
            <TextField name="title" label="Yangilik nomi" required />
            <TextField
              name="short_description"
              label="Yangilikning qisqacha tavsifi"
              required
            />
            <RichTextEditor name="content" label="Yangilik tavsifi" required />
            <FileField name="image" label="Yangilik rasmi" required />

            {!news && (
              <NotificationSwitch
                isNotified={isNotified}
                setIsNotified={setIsNotified}
              />
            )}
          </div>
          {news ? (
            <LoadingButton isLoading={isNewsEditPending}>
              Tahrirlash
            </LoadingButton>
          ) : (
            <LoadingButton isLoading={isNewsCreatePending}>
              Saqlash
            </LoadingButton>
          )}
        </form>
      </Form>
      <AlertDialog
        alertTitle="Yaratilayotgan yangilik bildirishnoma sifatida jo'natilishini xohlaysizmi?"
        alertCancel="Yo'q"
        alertActionTitle="Ha"
        alertCancelFucntion={() => setSheetOpen(false)}
        alertActionFunction={sendNotification}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </>
  );
}
