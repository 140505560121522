export interface Notification {
  _id: string;
  title: string;
  body: string;
  image?: string;
  type?: NotificationType;
  entity_id?: string;
  date: string;
}

export interface NotificationInput {
  title: string;
  body: string;
  image?: string;
  type?: NotificationType;
  entity_id?: string;
}

export interface NotificationEditBody {
  id: string;
  values: NotificationInput;
}

export enum NotificationType {
  AFISHA = 'afisha',
  TOURNAMENT = 'tournament',
  REVIEW = 'review_game',
  LIVE = 'live',
  NEWS = 'news',
  GRANDMASTER = 'grandmaster',
  BOOK = 'book',
  COURSE = 'course',
  MODULE = 'module',
  UZCHESSBOT = 'uzchesBot'
}
