import React, { useContext } from 'react';

import MainLayout from 'layout/MainLayout';
import AuthLayout from 'layout/AuthLayout';
import { Routes as DOMRoutes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'components/ui/toaster';
import { useRefreshToken } from 'modules/auth/hooks/useRefreshToken';
import AuthPage from 'pages/Auth';
import HomePage from 'pages/Home';
import CoursesPage from 'pages/Courses';
import ModulesPage from 'pages/Modules';
import LessonsPage from 'pages/Lessons';
import QuizzesPage from 'pages/Quizzes';
import GrandmastersPage from 'pages/Grandmasters';
import GrandmasterBotsPage from 'pages/GrandmasterBots';
import NewsPage from 'pages/News';
import BooksPage from 'pages/Books';
import AfishaPage from 'pages/Afisha';
import ReviewGamesPage from 'pages/ReviewGames';
import LiveStreamsPage from 'pages/LiveStreams';
import PuzzlesPage from 'pages/Puzzles';
import NotificationsPage from 'pages/Notifications';
import TournamentsPage from 'pages/Tournaments';
import TournamentParticipantsPage from 'pages/TournamentParticipants';
import { AuthContext } from 'providers/auth';
import ModuleBots from 'pages/ModuleBots';
import UzchessBots from 'pages/UzchessBot/UzchessBot';

export const Routes = () => {
  const { pathname } = useLocation();
  const { isLoading } = useRefreshToken();
  const { isAuthenticated } = useContext(AuthContext);
  const routes = [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/courses',
      element: <CoursesPage />,
    },
    {
      path: '/courses/:courseId',
      element: <ModulesPage />,
    },
    {
      path: '/courses/:courseId/:moduleId',
      element: <LessonsPage />,
    },
    {
      path: '/courses/:courseId/:moduleId/:lessonId',
      element: <QuizzesPage />,
    },
    {
      path: '/grandmasters',
      element: <GrandmastersPage />,
    },
    {
      path: '/grandmaster-bots',
      element: <GrandmasterBotsPage />,
    },
    {
      path: '/news',
      element: <NewsPage />,
    },
    {
      path: '/books',
      element: <BooksPage />,
    },
    {
      path: '/afisha',
      element: <AfishaPage />,
    },
    {
      path: '/review-games',
      element: <ReviewGamesPage />,
    },
    {
      path: '/tournaments',
      element: <TournamentsPage />,
    },
    {
      path: '/tournaments/:tournamentId',
      element: <TournamentParticipantsPage />,
    },
    {
      path: '/live-streams',
      element: <LiveStreamsPage />,
    },
    {
      path: '/puzzles',
      element: <PuzzlesPage />,
    },
    {
      path: '/notifications',
      element: <NotificationsPage />,
    },
    {
      path: '/module-bots',
      element: <ModuleBots />,
    },
    {
      path: '/module-bots/:id',
      element: <UzchessBots />,
    },
  ];

  return (
    <>
      {pathname === '/statistics' ? (
        <DOMRoutes>
          <Route
            path={'/statistics'}
            element={
              <div className="container py-10">
                <HomePage />
              </div>
            }
          />
        </DOMRoutes>
      ) : (
        <>
          {isLoading ? null : isAuthenticated ? (
            <MainLayout>
              <DOMRoutes>
                {routes.map(route => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={route.path}
                  />
                ))}
              </DOMRoutes>
            </MainLayout>
          ) : (
            <AuthLayout>
              <DOMRoutes>
                <Route path="/" element={<AuthPage />} />
                <Route path="*" element={<AuthPage />} />
              </DOMRoutes>
            </AuthLayout>
          )}
        </>
      )}
      <Toaster />
    </>
  );
};
