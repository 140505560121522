import http from 'services/api';
import { TournamentEditBody, TournamentInput } from './types';

export const GetTournamentsList = async (currentPage: number) => {
  return await http.get(`/tournaments?page=${currentPage}`);
};

export const CreateTournament = async (values: TournamentInput) => {
  return await http.post(`/tournaments/`, values);
};

export const EditTournament = async ({ values, id }: TournamentEditBody) => {
  return await http.put(`/tournaments/${id}`, values);
};

export const DeleteTournament = async (id: string) => {
  return await http.delete(`/tournaments/${id}`);
};
