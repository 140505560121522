import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getModuleBotsList } from '../adapters';
import { GetModuleBotList } from '../api';

export const useModuleBotsList = () => {
    const initialData = {
        data: getModuleBotsList(),
    };
    const { data = initialData, ...args } = useQuery({
        queryKey: ['module_bots_list'],
        queryFn: () => GetModuleBotList(),
        select: data => ({
            data: getModuleBotsList(get(data, 'data.data')),
        }),
    });

    return {
        ...data,
        ...args,
    };
};
